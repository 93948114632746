import React from "react"
import queryString from "query-string"
import { useAllTags } from "../../hooks/wp/useAllTags"
import { StateContext } from "../../components/PageLayout/StateContext"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SectionWrapper from "../../components/DesignSystem/components/SectionWrapper"
import { useSearch } from "../../hooks/wp/useSearch"

const SearchPage = ({ location }) => {
  const urlParameters = queryString.parse(location.search)
  const data = useAllTags(urlParameters.tag)
  const search = useSearch(urlParameters.search)

  return (
    <SectionWrapper fullBleed={true}>
      <div
        style={{
          marginTop:
            StateContext.width > 1015
              ? 300
              : StateContext.width < 500
              ? 130
              : 115 + 50 + "px",
        }}
      >
        <SectionWrapper>
          <h1 className="font-bold uppercase text-4xl">
            <span className="font-light">
              {urlParameters.search !== undefined &&
              StateContext.locale === "it"
                ? "Risultati per: "
                : "Results for: "}
            </span>
            {urlParameters.tag !== undefined
              ? urlParameters.tag
              : urlParameters.search !== undefined && urlParameters.search}
          </h1>
        </SectionWrapper>
        <SectionWrapper>
          {urlParameters.search !== undefined && search.posts !== null && (
            <>
              <h2 className="uppercase pb-4 font-bold">Posts</h2>
              <div className="md:grid md:grid-cols-3 gap-x-4 gap-y-8">
                {search.posts.map((post, index) => {
                  return (
                    <Link
                      key={index}
                      to={post.uri}
                      className="uppercase hover:opacity-70 duration-300"
                    >
                      {post.featuredImage?.node.localFile !== undefined && (
                        <GatsbyImage
                          image={getImage(post.featuredImage?.node.localFile)}
                          alt=""
                        />
                      )}
                      <div className="px-4 md:px-0">{post.title}</div>
                    </Link>
                  )
                })}
              </div>
            </>
          )}
        </SectionWrapper>
        <SectionWrapper>
          {urlParameters.search !== undefined && search.pages !== null && (
            <>
              <h2 className="uppercase pb-4 font-bold">Pages</h2>
              {search.pages.map((post, index) => {
                return (
                  <Link
                    key={index}
                    to={post.uri}
                    className="uppercase hover:opacity-70 duration-300"
                  >
                    {post.featuredImage?.node.localFile !== undefined && (
                      <GatsbyImage
                        image={getImage(post.featuredImage?.node.localFile)}
                        alt=""
                      />
                    )}
                    <div className="px-4 md:px-0">{post.title}</div>
                  </Link>
                )
              })}
            </>
          )}
        </SectionWrapper>
        {data?.result !== "" && (
          <div className="grid md:grid-cols-3 gap-x-4 gap-y-4 pt-8">
            {data?.result.map(({ post }, index) => {
              post =
                StateContext.locale !== "it" && post.translated.length > 0
                  ? post.translated[0]
                  : post
              return (
                <Link
                  key={index}
                  to={post.uri}
                  className="uppercase hover:opacity-70 duration-300"
                >
                  {post.featuredImage?.node.localFile !== undefined && (
                    <GatsbyImage
                      image={getImage(post.featuredImage?.node.localFile)}
                      alt=""
                    />
                  )}
                  <div className="px-4 md:px-0">{post.title}</div>
                </Link>
              )
            })}
          </div>
        )}
      </div>
    </SectionWrapper>
  )
}

export default SearchPage
